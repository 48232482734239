/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:20b1a8cb-cf73-46f9-8918-a71d0d72deab",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_0lK0HoWu9",
    "aws_user_pools_web_client_id": "18k031agpsjga5dcd7etef13ln",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://5yjxbrd6nbgd5an6nnh6mljcwi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-cvfx2nw5kjha3cta4d5zgd5rri",
    "aws_user_files_s3_bucket": "atmmedia42251-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
